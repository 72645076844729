















































































































































































































































































































import { SearchBuilder } from "@/builder";
import SelectMechanic from "@/components/custom/select/SelectMechanic.vue";
import { debounceProcess } from "@/helpers/debounce";
import MNotificationVue from "@/mixins/MNotification.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { DataListMasterAsset } from "@/models/interface/assets.interface";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataResponseGetListWorkOrder,
  DataResponseGetListWorkOrderHeader,
  DataResponseWorkOrderResult,
  DataWarehouseBranch,
} from "@/models/interface/logistic.interface";
import { DataListProduct } from "@/models/interface/product.interface";
import { ResultWorkOrderListFilterResponseDto } from "@/models/interface/work-order";
import { assetsServices } from "@/services/assets.service";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { productService } from "@/services/product.service";
import moment from "moment";
import printJs from "print-js";
import Vue from "vue";

export default Vue.extend({
  name: "ResultWorkOrder",
  components: {
    SelectMechanic,
  },
  mixins: [MNotificationVue],
  data() {
    this.getListCustomerMaintenanceNumber = debounceProcess(
      this.getListCustomerMaintenanceNumber,
      500
    );
    this.getListCustomer = debounceProcess(this.getListCustomer, 500);
    this.getUnitCode = debounceProcess(this.getUnitCode, 500);
    this.getListPartNumber = debounceProcess(this.getListPartNumber, 500);
    this.getListWorkOrderNumber = debounceProcess(
      this.getListWorkOrderNumber,
      500
    );
    this.getBranch = debounceProcess(this.getBranch, 500);
    return {
      DEFAULT_DATE_FORMAT,
      PAGE_SIZE_OPTIONS,
      form: this.$form.createForm(this, { name: "resultworkorder" }),
      page: 1,
      limit: 10,
      totalData: 0,
      params: {
        sorts: "workOrderDate:desc",
        showPrice: false,
      } as RequestQueryParamsModel,
      footer: {
        totalQtyWo: 0,
        totalQtyResult: 0,
      },
      loading: {
        table: false,
        loadingFind: false,
        loadingCustomerMaintenance: false,
        loadingWorkOrderNumber: false,
        loadingCustomerName: false,
        loadingUnitCode: false,
        loadingPartNumber: false,
        loadingBranch: false,
        download: false,
        print: false,
      },
      dataFinish: [
        { key: "YES", value: "Yes" },
        { key: "NO", value: "No" },
      ],
      dataListCustomerMaintenance: [] as ResultWorkOrderListFilterResponseDto[],
      dataWorkOrder: [] as DataResponseGetListWorkOrderHeader[],
      dataCustomer: [] as ContactData[],
      dataUnitCode: [] as DataListMasterAsset[],
      dataPartNumber: [] as DataResponseGetListWorkOrder[],
      dataBranch: [] as DataWarehouseBranch[],
      formRules: {
        customerMaintenanceNumber: {
          label: "lbl_rwo_number",
          name: "customerMaintenanceNumber",
          placeholder: "lbl_rwo_number",
          decorator: [
            "customerMaintenanceNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        finish: {
          label: "lbl_finish",
          name: "finish",
          placeholder: "lbl_finish",
          decorator: ["finish"],
        },
        workOrderNumber: {
          label: "lbl_wo_number",
          name: "workOrderNumber",
          placeholder: "lbl_wo_number",
          decorator: ["workOrderNumber"],
        },
        customerName: {
          label: "lbl_customer_name",
          name: "customerName",
          placeholder: "lbl_customer_name_placeholder",
          decorator: ["customerName"],
        },
        mechanic: {
          label: "lbl_mechanic_name",
          name: "mechanicName",
          placeholder: "lbl_mechanic_name",
          decorator: ["mechanic"],
        },
        branch: {
          label: "lbl_branch",
          name: "branch",
          placeholder: "lbl_branch",
          decorator: ["branch"],
        },
        unitCode: {
          label: "lbl_unit_code",
          name: "unitCode",
          placeholder: "lbl_unit_code_placeholder",
          decorator: ["unitCode"],
        },
        productCode: {
          label: "lbl_part_number",
          name: "productCode",
          placeholder: "lbl_part_number",
          decorator: ["productCode"],
        },
        dateFrom: {
          label: "lbl_date_from",
          name: "dateFrom",
          placeholder: "lbl_date_from",
          decorator: ["dateFrom"],
        },
        dateTo: {
          label: "lbl_date_to",
          name: "dateTo",
          placeholder: "lbl_date_to",
          decorator: ["dateTo"],
        },
      },
      columnsTable: [
        {
          title: this.$t("lbl_no"),
          dataIndex: "no",
          key: "no",
          width: "60px",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_branch"),
          dataIndex: "branchName",
          key: "branchName",
          width: "100px",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_result_wo_no"),
          dataIndex: "documentNumber",
          key: "documentNumber",
          width: "150px",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_wo_date"),
          dataIndex: "workOrderDate",
          key: "workOrderDate",
          width: "110px",
          scopedSlots: { customRender: "date" },
        },
        {
          title: this.$t("lbl_date_of_result_wo"),
          dataIndex: "completeDate",
          key: "completeDate",
          width: "135px",
          scopedSlots: { customRender: "date" },
        },
        {
          title: this.$t("lbl_csf_no"),
          dataIndex: "workOrderCSFNumber",
          key: "workOrderCSFNumber",
          width: "150px",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_wo_no"),
          dataIndex: "workOrderNumber",
          key: "workOrderNumber",
          width: "150px",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_mechanic"),
          dataIndex: "mechanic",
          key: "mechanic",
          width: "150px",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_customer_name"),
          dataIndex: "customer",
          key: "customer",
          width: "200px",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          key: "unitCode",
          width: "90px",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_part_no"),
          dataIndex: "partNumber",
          key: "partNumber",
          width: "180px",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_part_name"),
          dataIndex: "productUsedName",
          key: "productUsedName",
          width: "250px",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_asset_location"),
          dataIndex: "assetLocation",
          key: "assetLocation",
          width: "200px",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_qty"),
          dataIndex: "qtyUsed",
          key: "qtyUsed",
          width: "70px",
          scopedSlots: { customRender: "number" },
        },
        {
          title: this.$t("lbl_uom"),
          dataIndex: "uomUsedName",
          key: "uomUsedName",
          width: "70px",
          scopedSlots: { customRender: "nullable" },
        },

        {
          title: this.$t("lbl_status"),
          dataIndex: "status",
          key: "status",
          width: "80px",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_reason"),
          dataIndex: "reason",
          key: "reason",
          width: "80px",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_note"),
          dataIndex: "description",
          key: "description",
          width: "150px",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_action"),
          key: "operation",
          width: "90px",
          align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ],
      dataList: [] as DataResponseWorkOrderResult[],
    };
  },
  created() {
    this.getUnitCode("");
    this.getListCustomer("");
    this.getListCustomerMaintenanceNumber();
    this.getListWorkOrderNumber("");
    this.getListPartNumber("");
    this.getBranch("");
  },
  methods: {
    checkValue(value): string {
      if (value && !value.includes("undefined")) {
        return value;
      } else {
        return ",ALL";
      }
    },
    checkParams(res): string {
      let params = "";
      // company is mandatory
      res.csfNumber = undefined;
      params += this.$store.state.authStore.authData.companyName;
      params += this.checkValue(
        `,${
          this.dataBranch.find(dataFind => dataFind.id === res["branch"])?.name
        }`
      );
      params += this.checkValue(`,${res["customerMaintenanceNumber"]}`);
      params += this.checkValue(`,${res["workOrderNumber"]}`);
      params += this.checkValue(`,${res["csfNumber"]}`);
      const customerName = res.customerName
        ? res.customerName?.replace(",", "").trim()
        : "ALL";
      params += `,${customerName}`;
      params += `,${res && res.mechanic ? res.mechanic.label.trim() : "ALL"}`;
      params += this.checkValue(`,${res["unitCode"]}`);
      params += this.checkValue(`,${res["productCode"]}`);
      params += this.checkValue(
        res["dateFrom"]
          ? `,${moment(res["dateFrom"]).format("DD-MMM-yyyy")}`
          : null
      );
      params += this.checkValue(
        res["dateTo"] ? `,${moment(res["dateTo"]).format("DD-MMM-yyyy")}` : null
      );
      params += this.checkValue(`,${!res.finish ? "ALL" : res.finish}`);
      return params;
    },
    handleDownload() {
      this.form.validateFields((err, res) => {
        this.loading.download = true;
        this.params.params = this.checkParams(res);
        this.params.search = this.dynamicSearch(res);
        this.params.resultWo = res.finish;
        logisticServices
          .getDownloadResultdWorkOrder(this.params)
          .then(response => {
            if (response) {
              const url = window.URL.createObjectURL(new Blob([response]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "report_customer_maintenance.xlsx"); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .finally(() => (this.loading.download = false));
      });
    },
    handlePrint() {
      this.form.validateFields((err, res) => {
        this.loading.print = true;
        this.params.params = this.checkParams(res);
        this.params.search = this.dynamicSearch(res);
        this.params.resultWo = res.finish;
        logisticServices
          .getPrintResultdWorkOrder(this.params)
          .then(response => {
            if (response) {
              const url = window.URL.createObjectURL(new Blob([response]));
              printJs(url);
            }
          })
          .finally(() => (this.loading.print = false));
      });
    },
    responseViewTable(response) {
      if (!response.resultWorkOrderId) {
        this.showNotifInfo("notif_work_order_not_finish_yet");
        return;
      }

      this.$router.push(
        `/logistic/workorder/result/view/${response.resultWorkOrderId}`
      );
    },
    clearItems() {
      this.form.resetFields();
    },
    createNew() {
      this.$router.push("/logistic/workorder/result/create");
    },
    assignSearch(key, value, and): string {
      const objSearch = {
        customerMaintenanceNumber: and + `resultWorkOrderNo~${value}`,
        workOrderNumber: and + `workOrderNo~${value}`,
        customerName:
          and +
          `customerId~${
            this.dataCustomer.find(item => item.fullName == value)?.id
          }`,
        unitCode: and + `unitCode~${value}`,
        productCode: and + `productCode~*${value}*`,
        branch: and + `branchId~${value}`,
        mechanic:
          key === "mechanic" &&
          value &&
          value.key &&
          and + `mechanicId~${value?.key?.trim()}`,
      };

      if (key === "dateFrom" && value)
        return (
          and +
          `workOrderDate>=${moment(value)
            .set({ hour: 0, minute: 0, second: 0 })
            .utcOffset("+07")
            .format()}`
        );
      else if (key === "dateTo" && value)
        return (
          and +
          `workOrderDate<=${moment(value)
            .set({ hour: 23, minute: 59, second: 59 })
            .utcOffset("+07")
            .format()}`
        );
      else if (value && objSearch[key]) return objSearch[key];
      else return "";
    },
    dynamicSearch(res): string {
      let search = "";
      Object.keys(res).forEach(key => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    submitForm(reset = false) {
      this.form.validateFields((err, res) => {
        if (reset) this.page = 1;
        if (err) return;
        let params = {
          page: this.page - 1,
          limit: this.limit,
          sorts: "workOrderDate:desc",
          showPrice: false,
          resultWo: res.finish,
        } as RequestQueryParamsModel;
        if (this.dynamicSearch(res)) params.search = this.dynamicSearch(res);
        this.loading.loadingFind = true;
        this.loading.table = true;
        logisticServices
          .getListResultWorkOrder(params)
          .then(response => {
            this.params.params = this.checkParams(res);
            this.totalData = response.totalElements;
            this.footer = {
              totalQtyResult: response.totalQtyResult,
              totalQtyWo: response.totalQtyWo,
            };
            this.dataList = response.data.map((dataMap, index) => {
              return {
                ...dataMap,
                key: index,
                no: response.currentPage * this.limit + (index + 1),
              };
            });
          })
          .finally(() => {
            this.loading.loadingFind = false;
            this.loading.table = false;
          });
      });
    },
    getBranch(valueSearch) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };
      if (valueSearch)
        params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
      this.loading.loadingBranch = true;
      logisticServices
        .listWarehouseBranch(params, "")
        .then(response => {
          this.dataBranch = response.data;
        })
        .finally(() => (this.loading.loadingBranch = false));
    },

    getUnitCode(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search = `unitCode~*${valueSearch}*`;
      this.loading.loadingUnitCode = true;
      assetsServices
        .listMasterAsset(params)
        .then(data => (this.dataUnitCode = data.data))
        .finally(() => (this.loading.loadingUnitCode = false));
    },
    getListPartNumber(valueSearch) {
      let params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };
      if (valueSearch) params.search = `product.code~*${valueSearch}*`;
      this.loading.loadingWorkOrderNumber = true;
      logisticServices
        .getListWorkOrder(params)
        .then(response => {
          this.dataPartNumber = response.data;
          this.dataPartNumber = this.dataPartNumber.filter(
            (data, index, self) =>
              index === self.findIndex(t => t.productCode === data.productCode)
          );
        })
        .finally(() => (this.loading.loadingWorkOrderNumber = false));
    },
    getListCustomer(valueSearch) {
      let params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
        search: "customer~true", // active & incactive true while get list
      };
      if (valueSearch)
        params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;
      this.loading.loadingCustomerName = true;
      contactServices
        .listContactData(params)
        .then(response => (this.dataCustomer = response.data))
        .finally(() => (this.loading.loadingCustomerName = false));
    },
    getListCustomerMaintenanceNumber(valueSearch = "") {
      const params = new RequestQueryParams();
      if (valueSearch) {
        params.search = new SearchBuilder()
          .push(["documentNumber", valueSearch], { like: "both" })
          .build();
      }
      this.loading.loadingCustomerMaintenance = true;
      logisticServices
        .getListWorkOrderResultFilter(params)
        .then(response => (this.dataListCustomerMaintenance = response.data))
        .finally(() => (this.loading.loadingCustomerMaintenance = false));
    },
    getListWorkOrderNumber(valueSearch) {
      let params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };
      if (valueSearch) params.search = `documentNumber~*${valueSearch}*`;
      this.loading.loadingWorkOrderNumber = true;
      logisticServices
        .getListWorkOrderHeader(params)
        .then(response => (this.dataWorkOrder = response.data))
        .finally(() => (this.loading.loadingWorkOrderNumber = false));
    },
    getProducts(params: RequestQueryParamsModel): Promise<DataListProduct> {
      return productService.listProduct(params);
    },
    onChangeTable(pagination: {
      total: number;
      current: number;
      pageSize: number;
    }): void {
      this.page = pagination.current;
      if (this.limit !== pagination.pageSize) {
        this.page = 1;
      }

      this.limit = pagination.pageSize;
      this.submitForm();
    },
  },
});
